import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Globals } from './global.service';

@Injectable()
export class LoginService {
    constructor(private http: HttpClient,private globals: Globals) { }

    login(correo:string,clave:string) {
        return this.http.get(this.globals.host + this.globals.port +'/getLogin?correo=' + correo + '&contrasenia=' + clave);
    }
}